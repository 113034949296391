<template>
  <ion-page><cyan-page-wrapper nav1="*" 
    :burst="!$store.getters.userHasPermission('streamlined')"
    :iccNoPad="$store.getters.userHasPermission('streamlined')"
    :iccFlex="$store.getters.userHasPermission('streamlined')"
    :conDirectorio="$store.getters.userHasPermission('jrsl')"
    :title="esAzul ? 'Exit Polls' : 'Control Electoral Creo'"
    >

    <boton-directorio-streamlined class="mobileShow" v-if="$store.getters.userHasPermission('jrsl') "></boton-directorio-streamlined>

    <user-data :class="['cabeceraUsuario', 'mobileShowFlex', $store.getters.userHasPermission('streamlined') ? 'cabeceraPad' : false ]" :bottom="$store.getters.userHasPermission('jrsl')" />

    <!-- Home tradicional -->

    <div :class="[ 'homeButtonContainer', 'hbc-' + $store.getters.userNumPermissions ]" v-if="!$store.getters.userHasPermission('streamlined')">
      <div class="dummyDiv"></div>
      <ion-button v-if="$store.getters.userHasPermission('estructura')"
        strong="true"
        size="large"
        @click="$router.push('/partyLevels')"
      >
        <div>
          <ion-icon src="assets/images/Icono-N.svg"></ion-icon>
          PARTIDO
        </div>
      </ion-button>

      <ion-button v-if="$store.getters.userHasPermission('carga')"
        strong="true"
        size="large"
        @click="$router.push('/voters')"
      >
        <div>
          <ion-icon src="assets/images/Icono-Manos.svg"></ion-icon>
          CARGA ELECTORAL
        </div>
      </ion-button>

      <ion-button v-if="$store.getters.userHasPermission('directorio')"
        strong="true"
        size="large"
        @click="$router.push($store.getters.rutaDirectorio)"
      >
        <div>
          <ion-icon class="icon-d" :icon="helpBuoyOutline"></ion-icon>
          DIRECTORIO
        </div>
      </ion-button>

      <ion-button v-if="$store.getters.userHasPermission('punto')"
        strong="true"
        size="large"
        @click="$router.push($store.getters.rutaPuntoCyan)"
      >
        <div>
          <ion-icon class="icon-pc" :icon="megaphoneOutline"></ion-icon>
          PUNTO INFO
        </div>
      </ion-button>


      <ion-button v-if="$store.getters.userHasPermission('agente')"
        strong="true"
        size="large"
        @click="$router.push($store.getters.rutaAgente)"
      >
        <div>
          <ion-icon class="icon-a" :icon="trophyOutline"></ion-icon>
          CONTROL ELECTORAL
        </div>
      </ion-button>



      <div class="dummyDiv"></div>
    </div>

    <streamlined-home v-else></streamlined-home>

  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import { IonButton,IonIcon, IonPage } from "@ionic/vue";
import { megaphoneOutline, helpBuoyOutline, trophyOutline } from 'ionicons/icons';
import UserData from "@/components/UserData.vue";
import StreamlinedHome from "@/components/StreamlinedHome.vue"
import BotonDirectorioStreamlined from '@/components/BotonDirectorioStreamlined.vue';
export default defineComponent({
  name: "Home",
  components: {
    IonButton,
    IonIcon,
    IonPage,
    BotonDirectorioStreamlined,
    CyanPageWrapper,
    UserData,
    StreamlinedHome,
  },
  setup() {
    return {
      megaphoneOutline,
      trophyOutline,
      helpBuoyOutline
    }
  },
  computed: {
    esAzul() {
      const p = (this as any).$store.getters.userHasPermission;
      return p('busl') && !p('jrsl') && !p('pcsl');
    }
  },
  methods: {},
});
</script>

<style scoped>
ion-button div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
ion-button {
  height: 180px;
  width: 180px;
  margin: 20px;
}
ion-button ion-icon {
  padding-bottom: 20px;
  font-size: 80px;
}
.homeButtonContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; /*space-between;*/
  align-items: center;
}
.dummyDiv {
  visibility: hidden;
  display: none;
  width: 0;
  height: 0;
}

.hbc-0 .dummyDiv, 
.hbc-1 .dummyDiv, 
.hbc-2 .dummyDiv { display: block; }

.hbc-0.homeButtonContainer, 
.hbc-1.homeButtonContainer, 
.hbc-2.homeButtonContainer { justify-content: space-between; }

@media (max-width: 500px) {
  .hbc-0 .dummyDiv, 
  .hbc-1 .dummyDiv, 
  .hbc-2 .dummyDiv { display: none; }

  .hbc-0.homeButtonContainer, 
  .hbc-1.homeButtonContainer, 
  .hbc-2.homeButtonContainer { justify-content: center; }

  ion-button {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media (min-width: 768px) {
  ion-button {
    height: 30vw;
    width: 30vw;
    margin: 20px;
    --border-radius: 2vw;
  }
  ion-button ion-icon {
    padding-bottom: 20px;
    font-size: 15vw;
  }
  .homeButtonContainer {
    padding-top: 5vh;
  }
}
@media (min-width: 1200px) {
  ion-button {
    height: 360px;
    width: 360px;
    margin: 20px;
    --border-radius: 24px;
  }
  ion-button ion-icon {
    font-size: 180px;
  }
}

@media (max-height: 400px) {
  .cabeceraUsuario  {
    display: none;
  }
}

.icon-pc, .icon-d, .icon-a {
  --ionicon-stroke-width: 18px;
}

.cabeceraPad { padding: 4vh 2vh 2vh; }

</style>